
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { FullBleed, Spacer } from '@slrc/shared/ui';
import { CalculatorAndCalculation } from '@slrc/slrc-web/features/calculator';
import { NextSeo } from 'next-seo';

import { SEO_BASE_URL } from '../config/seo';
import styled from 'styled-components';
import { getYear } from 'date-fns';

const currentYear = getYear(new Date());
const seoTitle = `UK Student Finance Loan Repayment Calculator ${currentYear}`;
const seoDescription =
  'A free comprehensive UK student loan repayment calculator for Plan 1, Plan 2, Plan 4, Plan 5 and Postgraduate plan types. See the practical impact of a student loan on your pocket and whether making any voluntary payments will save you money.';

export function HomePage() {
  return (
    <>
      <NextSeo
        title={seoTitle}
        description={seoDescription}
        openGraph={{
          title: seoTitle,
          description: seoDescription,
          url: `${SEO_BASE_URL}`,
        }}
      />
      <h1>
        Calculate the <Underline>real</Underline> cost of your loan
      </h1>
      <FullBleed>
        <CalculatorAndCalculation />
        {/* TODO: new wizard */}
        {/* {showWizard ? <WizardCalculator /> : <CalculatorAndCalculation />} */}
      </FullBleed>
      <Spacer />
    </>
  );
}

export default HomePage;

const Underline = styled.span`
  text-decoration: underline var(--slrc-colour-primary-500) 6px;
`;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  